var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dropdown",
    {
      staticClass: "pdv-dropdown",
      attrs: {
        boundary: "viewport",
        variant: "outline-light",
        "menu-class": "position-fixed",
        "no-caret": "",
      },
      scopedSlots: _vm._u([
        {
          key: "button-content",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column align-items-center justify-content-center",
                },
                [
                  _c("b-icon", {
                    staticClass: "mb-1",
                    attrs: { scale: "1.5", icon: "credit-card-2-front" },
                  }),
                  _vm._v(" TEF "),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-dropdown-item",
        { on: { click: _vm.printLastReceipt } },
        [
          _c("b-icon", {
            attrs: {
              icon: "printer",
              variant: "outline-primary",
              "font-scale": "1",
            },
          }),
          _c("span", { staticClass: "dropdown-text" }, [
            _vm._v(_vm._s(_vm.$t("Reimpressão último comprovante"))),
          ]),
        ],
        1
      ),
      _c(
        "b-dropdown-item",
        { on: { click: _vm.showMessagesModal } },
        [
          _c("b-icon", {
            attrs: {
              icon: "card-list",
              variant: "outline-primary",
              "font-scale": "1",
            },
          }),
          _c("span", { staticClass: "dropdown-text" }, [
            _vm._v(_vm._s(_vm.$t("Visualizar mensagens/alertas"))),
          ]),
        ],
        1
      ),
      _c(
        "b-dropdown-item",
        { on: { click: _vm.refreshTables } },
        [
          _c("feather-icon", {
            staticClass: "text-body align-middle mr-25",
            attrs: { icon: "DownloadCloudIcon", size: "16" },
          }),
          _c("span", { staticClass: "dropdown-text" }, [
            _vm._v(_vm._s(_vm.$t("Atualizar tabelas"))),
          ]),
        ],
        1
      ),
      _vm.canRestartClient
        ? _c(
            "b-dropdown-item",
            {
              attrs: {
                title: _vm.$t(
                  "PAY_BOX_SALE.TEF.INSTRUCTION_TEF_SERVICE_RESTART"
                ),
              },
              on: { click: _vm.restartService },
            },
            [
              _c("b-icon", {
                attrs: {
                  icon: "gear",
                  variant: "outline-primary",
                  "font-scale": "1",
                },
              }),
              _c("span", { staticClass: "dropdown-text" }, [
                _vm._v(_vm._s(_vm.$t("Reiniciar serviço (PINPAD)"))),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }